import "../styles/Intro.scss";

const Intro = () => {
  // const checkpoint = 1000;

  // window.addEventListener("scroll", () => {
  //   const currentScroll = window.pageYOffset;
  //   let opacity;

  //   if (currentScroll <= checkpoint) {
  //     opacity = 1 - currentScroll / checkpoint;
  //   } else {
  //     opacity = 0;
  //   }

  //   document.querySelector(".intro").style.opacity = opacity;
  //   if (opacity < 0.95) {
  //     document.querySelector("text1").style.display = "block";
  //   } else {
  //     document.querySelector("text1").style.display = "none";
  //   }
  // });

  return (
    <div className="intro">
      {/* <div className="intro--line"></div> */}
    </div>
  );
};

export default Intro;
